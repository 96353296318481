:root {
    --primary-color: #0057b8;
    --primary-text-color: #ffffff;
    --secondary-color: #009fdb;
    --secondary-text-color: #ffffff;
    --tertiary-color: #25303a;
    --tertiary-text-color: #ffffff;
    --text-color: #1d2329;
    --background-color: #ffffff;
    --secondary-background-color: #f3f4f6;
    --bundle-text-color: #1d2329;
    --bundle-background-color: #baeefc;
    --bundle-container-color: #f2fafd;
    --hard-bundle-container-color: #f2fafd;
    --activated-color: #ffb000;
    --activated-text-color: #1d2329;
    --pause-color: #ff8500;
    --pause-text-color: #1d2329;
    --error-color: #c70032;
    --error-text-color: #1d2329;
    --trial-color: #dcf3fa;
    --trial-text-color: #1d2329;
    --activation-required-text-color: #888888; /* this color is for activation required text in my perks */
    --original-price-text: #454b52; /* this color is using for shopping cart */
    --price-text-period: #4a4a4a; /* this color is used in cart for period*/
    --button-background-color: #8066ff; /*using in button css*/
    --address-background-color: #f5f5f5; /*using in addressUpdtae css*/
    --usersetting-background-color: #0000004d; /*using in user settings css*/
    --login-background-color: #12171b; /*using in login css*/
    --description-hover-color: #e9e9e9; /*background color using for description while hover*/
    --active-button-color: #f9d207;
    --soft-bundle-background-color: #23e36f33;
    --soft-bundle-text-color: #000000;
    --soft-bundle-btn-background-color: #21bc5f;
    --inter-active-background-color: #0057b8;
    --inter-active-text-color: #1d2329;
    --DarkTheme: #000000;
    --LightTheme: #ffffff;

    --icon-hover-color: #c70032;
    --footer-background-color: #f4f5f9;
    --border-color: #dcdfe3;

    --activate-button-background-color: #0057b8;
    --activate-button-text-color: #ffffff;
    --change-plan-button-background-color: transparent;
    --change-plan-button-text-color: #ffffff;
    --pause-button-background-color: transparent;
    --pause-button-text-color: #ffffff;
    --cancel-button-background-color: transparent;
    --cancel-button-text-color: #f32e13;
    --checkout-discount-color: #2d7e24;
    --separator-color: #bdc2c7;
    --checkout-consent-border-color: #686e74;
    --checkout-border-color: #dcdfe3;
    --checkout-faq-background-color: #f2fafd;
    --icon-hover-color: #c70032;
    --footer-background-color: #f4f5f9;
}
