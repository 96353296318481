@import 'client/styles/font.css';
@import 'client/styles/color.css';

.marketplace-regular {
    font-family: var(--font-name);
    font-style: normal;
    font-weight: 400;
}

.marketplace-regular-italic {
    font-family: var(--font-name-italic);
    font-style: italic;
    font-weight: 400;
}

.marketplace-bold {
    font-family: var(--font-name-bold);
    font-style: normal;
    font-weight: 700;
}

.marketplace-bold-italic {
    font-family: var(--font-name-bold-italic);
    font-style: italic;
    font-weight: 700;
}

:root {
    /* --focus-background-color: #0057b8;
    --focus-text-color: #12171b;     
    --focus-border-color: #0057b8;
    --active-text-color: #0057b8;
    --primary-color: #12171b;
    --badge-color: #12171b;
    --interactive-background-color: #12171b; */
}

html {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

body {
    font-family: var(--font-name);
    font-weight: 400;
    margin: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    letter-spacing: -0.03em;
}

/* specific CSS for iPhone XS/ios 17.2 */
@supports (-webkit-touch-callout: none) and (not (overflow: -moz-scrollbars-none)) {
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
        #root, body {
            overflow: auto;
        }
        body, html {
            height: auto;
        }
    }
}

#root {
    overflow: hidden;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.menu-icon-style {
    fill: black;
    width: 24px;
    height: 24px;
}

.webstv-icon {
    fill: black;
    width: 16px;
    height: 16px;
}

::-webkit-scrollbar {
    height: 4px;
    border-radius: 8px;
    width: 9px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 8px;
}
