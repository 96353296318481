.casting {
    width: 140px;
    height: 190px;
    border-radius: 12px;
    padding: 10px;
}

.castingFocused,
.casting:hover {
    background: #ffffff33;
}

.casting img {
    width: 140px;
    height: 140px;
    border-radius: 70px;
}

.casting div {
    font-size: var(--font-size-body-lg);
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--primary-text-color);
    margin-top: 5px;
}
