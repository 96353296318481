.multiple-content-block-tile-list-container {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 80px;
    padding-bottom: 80px;
    align-items: center;
}

.multiple-content-block-tile-list-title {
    font-size: var(--font-size-display-sm);
    text-align: center;
    color: var(--text-color);
    @media (max-width: 750px) {
        font-size: var(--font-size-title);
    }
}

.multiple-content-block-tile-list-sub-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.multiple-content-block-tile-list-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 60px;
}

.multiple-content-block-tile-list-title-logo {
    width: 80px;
    height: 45px;
    border-radius: 6px;
}
