.subMenu-wrapper {
    position: fixed;
    right: 1.9%;
    top: 78px;
    background-color: var(--background-color);
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    list-style-type: none;
    margin: 5px 0;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.subMenu-box-arrow-style {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.subMenu-list-item {
    font-size: var(--font-size-body-sm);
    cursor: pointer;
    color: black;
    padding-inline: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.subMenuItemSelected{
    color: var(--primary-color) !important;
    font-size: var(--font-size-body-sm);
    cursor: pointer;
    color: black;
    padding-inline: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.subMenuActive {
    color: var(--primary-color);
}

.subMenu-list-item:hover {
    color: var(--primary-color);
}
