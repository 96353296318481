.volt-input {
    padding: 0.75rem 0.75rem;
    border: 2px solid #ced4da;
    border-radius: 6px;
    font-size: var(--font-size-base);
    color: #495057;
    outline: none;
}

.volt-input:hover {
    border: 2px solid #a2a2a2;
}

.volt-input:focus {
    border: 2px solid #2988e5;
}
