.tileLive,
.tileLiveRow {
    width: 240px;
    height: auto;
    border-radius: 12px;
    transition: transform 0.2s;
    border: 4px solid transparent;
    margin-bottom: 20px;
    cursor: pointer;
}

.tileLiveFocus,
.tileLive:hover {
    background-color: #03f182;
    border: 4px solid #03f182;
    z-index: 1;
    position: relative;
    transition: all 0.3s;
    transform: scale(1.15);
}

.tileLiveSelected {
    background-color: #03f182;
    border: 4px solid #03f182;
    z-index: 1;
    position: relative;
}

.tileLiveTitle:hover,
.tileLiveTitle.tileLiveFocus {
    width: 272px;
    height: 203px;
    margin: -12px -16px;
}

.tileLiveTitle:hover .tileLiveImg,
.tileLiveTitle.tileLiveFocus .tileLiveImg {
    width: 272px;
    height: 153px;
}

.tileLiveRow:hover {
    background-color: #ffffff33;
    border: 4px solid #ffffff33;
    z-index: 1;
    position: relative;
    transition: all 0.5s;
    transform: scale(1.15);
}

.tileLiveFocusRow {
    background-color: #ffffff33;
    border: 4px solid #ffffff33;
    z-index: 1;
    position: relative;
    transition: all 0.5s;
    transform: scale(1.15);
}

.tileLiveActive {
    position: relative;
    display: grid;
    justify-items: center;
}

.tileLiveFocus .tileLiveDesc,
.tileLive:hover .tileLiveDesc {
    color: var(--focus-text-color);
}

.tileLiveActive .tileLiveDesc img {
    width: 40px;
    height: 26.67px;
}

.tileLiveImg {
    position: relative;
    width: 240px;
    height: 135px;
    border-radius: 12px;
    text-align: center;
}

.tileLiveImg img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: #21262a;
}

.tileLiveDesc {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    color: var(--White-Theme, #eee);
    padding: 3px;
    align-items: center;
}

.chNo {
    font-size: var(--font-size-body-sm);
    font-style: normal;
    line-height: 20px; /* 142.857% */
    opacity: 0.7;
}

.tileLive .tileicons,
.tileLiveFocus .tileicons {
    display: none;
}

.tileLive:hover .tileicons,
.tileLiveFocus:hover .tileicons {
    display: flex;
}

.tileLive:hover .tileLiveLogo,
.tileLiveFocus .tileLiveLogo {
    display: none;
}
