.soft-bundle-item-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.soft-bundle-item-left-component {
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;
}

.soft-bundle-item-right-component {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
}

.soft-bundle-item-title {
    font-weight: 500;
    font-size: var(--font-size-body);
    color: black;
}

.soft-bundle-item-logo {
    height: 60px;
    width: 100px;
    border-radius: 4px;
}

.soft-bundle-item-divider {
    height: 1px;
    background-color: #888888;
    opacity: 20%;
    margin-inline: 10px;
    margin-bottom: 20px;
}

.soft-bundle-item-original-price {
    font-weight: 400;
    font-size: var(--font-size-body);
    color: #888888;
    text-decoration: line-through;
}

.soft-bundle-item-price {
    font-weight: 700;
    font-size: var(--font-size-body);
    color: black;
}

.soft-bundle-item-duration {
    font-size: var(--font-size-body-sm);
    font-weight: 400;
    color: #4a4a4a;
}
